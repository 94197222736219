exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/events/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-events-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletters-index-js": () => import("./../../../src/pages/newsletters/index.js" /* webpackChunkName: "component---src-pages-newsletters-index-js" */),
  "component---src-pages-newsletters-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/newsletters/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-newsletters-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-turingcup-js": () => import("./../../../src/pages/turingcup.js" /* webpackChunkName: "component---src-pages-turingcup-js" */)
}

